import './App.css';
import 'leaflet/dist/leaflet.css';

import { ImageOverlay, LayersControl, MapContainer, TileLayer } from 'react-leaflet'
import { CRS } from 'leaflet';

const R2_URL = "https://pub-45cb64d918de4c709560cc2f67277b78.r2.dev"

function App() {

  const center = [0, 0];
  const bounds = [[0, 0], [4096, 4096]];

  return (
    <div className="App">
      <MapContainer center={center} zoom={0} scrollWheelZoom={true} crs={CRS.Simple} bounds={bounds} minZoom={0} maxZoom={7}>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Map 1">
            <TileLayer
              url={R2_URL + "/maps/1/{z}/{x}/{y}{r}.png"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map 2">
            <TileLayer
              url={R2_URL + "/maps/2/{z}/{x}/{y}{r}.png"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map 3">
            <TileLayer
              url={R2_URL + "/maps/3/{z}/{x}/{y}{r}.png"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map 4">
            <TileLayer
              url={R2_URL + "/maps/4/{z}/{x}/{y}{r}.png"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map 5">
            <TileLayer
              url={R2_URL + "/maps/5/{z}/{x}/{y}{r}.png"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map 6">
            <TileLayer
              url={R2_URL + "/maps/6/{z}/{x}/{y}{r}.png"}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
    </div>
  );
}

export default App;
